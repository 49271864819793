import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Architecture of the box`}</h5>
    <ol>
      <li parentName="ol">{`Have a box of empty mate bottles.`}</li>
      <li parentName="ol">{`Drill a hole into every lid that is big enough to fit a LED light.`}</li>
      <li parentName="ol">{`Cover the sides of the bottle to only let the light go out at the bottom.`}</li>
      <li parentName="ol">{`Get some programmable LEDs and put them into the lid holes.`}</li>
      <li parentName="ol">{`Connect the LEDs to a arduino and a power supply.`}</li>
      <li parentName="ol">{`Programm the LEDs to light up.`}</li>
    </ol>
    <p>{`The next pages will show you how I built it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      